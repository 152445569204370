var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-bodyz"
  }, [_c('div', {}, [_c('h5', {
    staticClass: "text-center mb-4 mt-4 text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("info-abt-property")))]), _c('ul', {
    staticClass: "nav nav-tabs mt-2"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.harta == 1 ? 'active' : '',
    attrs: {
      "to": {
        name: 'pindaan.part_d-tidakalih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: this.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("immovable-prop")))])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.harta == 2 ? 'active' : '',
    attrs: {
      "to": {
        name: 'pindaan.part_d-alih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: this.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("mov-prop")))])], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('router-view')], 1)])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.part_e',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }